import React from "react"
import Layout from "../components/layout";
import { Link } from "gatsby"
import Seo from "../components/seo"

export default function Content() {
    return <Layout>
        <Seo title="Omnomnom - Mentions légales"/>
        <div className="row">
            <div className="col-12">
                <Link to="/" className="text-dark"><i className="fas fa-arrow-left small"/> Retour à la liste des recettes</Link>
                <h1>Politique de confidentialité</h1>
                <p>Il n'y a pas de cookie sur ce site et aucune donnée n'est collectée.</p>
            </div>
        </div>
    </Layout>
}